.homeLeft {
    background-color: var(--background_lightlight);
    border-right: 1px var(--border_color_bold) solid;
}

.homeLeftGridParent {
    height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.homeIcon {
    background-color: transparent;
    width: 90%;
    box-sizing: border-box;
    padding: 10px 10px 10px 10px;
    margin: 0 auto;
    display: block;
}

.allSelectItems {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 10px 0px 10px;
}

.selectItem {
    box-sizing: border-box;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.selectItem:hover {
    background-color: var(--select_background_hover);
    border-color: transparent;
}

.selectItem:has(> .radioSelectLeftItem:checked) {
    background-color: var(--select_background);
    border-color: transparent;
}

.radioSelectLeftItem {
    display: none;
}

.selectItem img {
    background-color: transparent;
    width: 50px;
    box-sizing: border-box;
    padding: 3px;
    /* border: solid 3px #CCCCCC; */
    border-radius: 5px;
}

.selectItem label {
    font-size: 16px;
    padding-left: 10px;
    cursor: unset;
}

.userInfo {
    position: absolute;
    bottom: 0px;
    padding: 10px;

}

.userId {
    padding-bottom: 20px;
}

.userIdItems {
    padding: 5px 0px 0px 10px;
}

.permissionId {
    padding-bottom: 20px;
}

.permissionIdItems {
    padding: 5px 0px 0px 10px;
}

.toSignin {
    width: 100%;
    padding-left: 10px;
}

.buttonSignout {
    display: block;
    margin: auto;
}