.compAssetComment {
    padding: 5px 0px 5px 0px;
    min-height: 100px;
}

.areaComment {
    overflow-y: auto;
    height: 70%;
}

.areaNewComment {
    overflow-y: visible;
    padding: 0px;
}

.areaCommentLabelTitle {
    padding: 0px;
}

.labelTitle {
    padding: 0px 0px 5px 0px;
}

.areaAllComment {
    padding: 5px 5px 0px 5px;
    border: 1px solid var(--border_color);
}

.areaTableAllCommentTitle {
    padding: 0px 0px 5px 0px;
}

/*.areaTableAllComment {
    height: calc(100vh - 190px);
    overflow-y: auto;
}*/

.tableAllComment {
    border-collapse: collapse;
}

.tableAllComment>thead>tr>th {
    font-weight: unset;
    text-align: unset;
    border: 1px solid var(--border_color_bold);
    padding: 0px 0px 0px 5px;
    font-size: 12px;
}

.tableAllComment>tbody>tr>td {
    border: 1px solid var(--border_color_bold);
    font-size: 12px;
}

/* 
.tableAllComment tr:nth-child(odd) td {
    background: white;
} */

.tableAllCommentHeader {
    width: 100%;
    height: 0px;
    background-color: var(--button_background);
    color: white;
    font-size: x-small;
    position: sticky;
    top: 0;
    z-index: 1;
}

.tableAllCommentBody {
    width: 100%;
    height: 0px;
    font-size: x-small;
    background-color: var(--background_lightlight);
    z-index: 0;
}

.inputNewComment {
    width: 100%;
}

.areaNewCommentButton {
    width: 130px;
    text-align: right;
}