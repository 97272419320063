.compSearchAndFilter {
    padding: 10px;
    margin: -1px -1px -1px -1px;
    border: 1px solid var(--border_color);
}

.checkboxFilterOnOff {
    display: none;
}

.labelFilterOnOff {
    padding: 5px 5px;
    white-space: nowrap;
}

.labelFilterOnOff > img {
    vertical-align: text-top;
    margin-right: 5px;
}

.compSearchAndFilter > .areaLower {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 0px 0px 0px;
}

.compSearchAndFilter > .areaLower > table {
    border: 1px solid var(--border_color);
    padding: 5px;
}

.areaSearchItem {
    padding: 0px 10px 0px 0px;
}

.areaNoWrap {
    white-space: nowrap;
}

.areaSearchItem > label {
    padding: 0px 10px 0px 0px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    box-sizing: border-box;
    font-size: 12px;
    width: 6rem;
}

/* .react-datepicker__close-icon::before,
.react-datepicker__close-icon::after {
  background-color: grey;
} */

.areaInputSearchText {
    display: inline-block;
    position: relative;
    width: 100%;
}

.areaInputSearchText .buttonInputSearchText_Clear {
    content: "";           /* 疑似要素に必須 */
    width: 16px;
    height: 16px;
    background: url(../../public/images/close.png) no-repeat center center / auto 100%;
    display: inline-block; /* 高さを持たせるためにインラインブロック要素にする */
    position: absolute;    /* 相対位置に指定 */
    top: 50%;              /* アイコンの位置。微調整してね */
    transform: translateY(-50%);
    right: 5px;             /* アイコンの位置。微調整してね */
    cursor: pointer;	
}

.areaInputSearchText .buttonInputSearchText_Search {
    content: "";           /* 疑似要素に必須 */
    width: 16px;           /* アイコンの横幅 */
    height: 16px;          /* アイコンの高さ */
    background: url(../../public/images/search.png) no-repeat center center / auto 100%; /* 背景にアイコン画像を配置 */
    display: inline-block; /* 高さを持たせるためにインラインブロック要素にする */
    position: absolute;    /* 相対位置に指定 */
    top: 50%;              /* アイコンの位置。微調整してね */
    transform: translateY(-50%);
    left: 5px;             /* アイコンの位置。微調整してね */
    cursor: pointer;
}

.inputSearchText {
    width: calc(100% - 30px);
    padding: 5px 0px 5px 30px;
}

.areaIncludeSpeechAnalysis {
    position: absolute;
    width: 98%;
    text-align: right;
}

.areaDetailSearch {
    padding: 20px 0px 0px 0px;
}

.buttonSearch {
    margin: 0px 0px 0px 10px;
}

.buttonSearch > label > img {
    vertical-align: text-top;
    margin-right: 5px;
}

.buttonClear {
    margin: 0px 0px 0px 10px;
}

.areaAlignRight {
    text-align: right;
    padding: 0px 0px 0px 0px;
}