.compViewLimitation {
	height: 100vh;
	overflow: hidden;

	border: 1px solid var(--border_color);
}

.gridViewLimitation {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.areaAllHeight {
	padding: 5px;

	width: 100%;
	height: 100%;
	border: 1px solid var(--border_color);
}

.areaViewLimitation {
	padding: 5px 5px 15px 5px;
	height: calc(100% - 60px);
	overflow-y: hidden;
	align-items: center;
	justify-content: center;
	margin: 50px auto;
}

.areaTableTop {
	width: 70%;
	height: 7%;
	align-items: center;
	justify-content: center;
	margin: auto auto;
}

.areaLabelTitle {
	float: left;
	padding: 20px 0 0 0;
}

.areaTableStudents {
	width: 70%;
	height: auto;
	overflow-y: auto;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}

.tableStudents {
	border-collapse: collapse;
}

.tableStudents>thead>tr>th {
	font-weight: unset;
	text-align: unset;
	border: 1px solid var(--border_color_bold);
	padding: 0px 0px 0px 5px;
}

.tableStudents>tbody>tr>td {
	border: 1px solid var(--border_color_bold);
}

.tableStudentsHeader {
	width: 100%;
	height: 0px;
	background-color: var(--button_background);
	color: white;
}

.tableStudentsBody {
	width: 100%;
	height: 0px;
	background-color: var(--background_white);
}

.areaButtonTableTop {
	width: 100%;
	float: left;
}

.buttonTableTop {
	float: right;
	margin: 5px 0px 5px 5px;
}

.areaTableBottom {
	width: 70%;
	height: 7%;
	align-items: center;
	justify-content: center;
	margin: auto auto;
}