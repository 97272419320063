.homeRight {
    background-color: white;
}

.areaTab {
    padding: 10px 0px 5px 0px;
    /* white-space: nowrap; */
}

.radioSelectTab {
    display: none;
}

.radioSelectTab:checked + .labelTab {
    background-color: var(--select_background);
    border-color: transparent;
}

.labelTab {
    box-sizing: border-box;
    border: 1px solid var(--border_color);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 5px 10px 5px 10px;
    margin: 0px -1px 0px 0px;
    white-space: nowrap;
}

.labelTab:hover {
    background-color: var(--select_background_hover);
}
