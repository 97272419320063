.formContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleIcon {
    display: block;
    margin: auto;
    padding-bottom: 30px;
    width: 50%;
}

.formContainer form {
    padding: 10px;
}

.uiForm {
    border: 1px var(--border_color_bold) solid;
}

.formTitle {
    background-color: var(--background_light);
    padding: 10px;
    border-bottom: 1px var(--border_color_bold) solid;
}

.formField {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 20px 0px;
}

.formField input {
    border: 1px var(--border_color_bold) solid;
    padding: 10px;
    border-radius: 3px;
}

.formField input:focus {
    outline: none;
}

.formField label {
    margin-bottom: 10px;
}

.areaErrorMsg {
    height: auto;
}

.errorMsg {
    color: red;
    margin: 0;
}

.buttonSignin {
    background-color: var(--button_background);
    width: 100%;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    padding: 10px 30px;
    color: white;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .buttonSignin:hover {
    background-color: var(--button_background_hover);
  }