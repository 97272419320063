.compSpeechAnalyze {
    height: 100vh;
    padding: 5px;

    border: 1px solid var(--border_color);
}

.gridSpeechAnalyze {
    display: grid;
    grid-template-rows: auto auto 10vh;
}

.areaActionApeechAnalyze {
    padding: 5px;
}

.areaAllText {
    padding: 5px 5px 0px 5px;
    border: 1px solid var(--border_color);
}

.areaTableAllTextTitle {
    padding: 0px 0px 5px 0px;
}

.areaTableAllText {
    position: relative;
    height: calc(100vh - 250px);
    overflow-y: scroll;
}

.tableAllText {
    position: absolute;
    z-index: 2;
    width: 100%;
    border-collapse: collapse;
}

.tableAllText>thead>tr>th {
    font-weight: unset;
    text-align: unset;
    border: 1px solid var(--border_color_bold);
    padding: 0px 0px 0px 5px;
}

.tableAllText>tbody>tr>td {
    border: 1px solid var(--border_color_bold);
    padding: 7px 0px;
}

.tableAllTextHeader {
    width: 100%;
    height: auto;
    background-color: var(--button_background);
    color: white;

    position: sticky;
    top: -1px;
}

.tableAllTextBody {
    width: 100%;
    height: 0px;
    background-color: var(--background_lightlight);
}

.normalSpeech {
    font-size: large;
    white-space: pre-line;
    padding: 0px 3px;
}

.highlightSpeech {
    background-color: yellow;
}

.speech_text_playing {
    color: lightcoral;
}

.areaSaveAndCopy {
    overflow: hidden;
    padding: 5px;
}

.areaSaveAndCopy span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.buttonInsertTag {
    width: auto;
    margin: 10px 10px;
    padding: 5px 10px;
    cursor: pointer;

    position: absolute;
    bottom: 0px;
    left: 0px;
}

.buttonSave {
    width: auto;
    margin: 10px 10px;
    padding: 5px 10px;
    cursor: pointer;

    position: absolute;
    bottom: 0px;
    right: 150px;
}

.buttonSave>*>label>img {
    width: 16px;
    vertical-align: middle;
    margin-right: 5px;
}

.buttonCopyClipbord {
    width: auto;
    margin: 10px 10px;
    padding: 5px 10px;
    cursor: pointer;

    position: absolute;
    bottom: 0px;
    right: 0px;
}

.buttonCopyClipbord>*>label>img {
    width: 16px;
    vertical-align: middle;
    margin-right: 5px;
}

.areaInputSearchText {
    display: inline-block;
    position: relative;
    width: 100%;
}

.areaInputSearchText .buttonInputSearchText_Clear {
    content: "";
    /* 疑似要素に必須 */
    width: 16px;
    height: 16px;
    background: url(../../public/images/close.png) no-repeat center center / auto 100%;
    display: inline-block;
    /* 高さを持たせるためにインラインブロック要素にする */
    position: absolute;
    /* 相対位置に指定 */
    top: 50%;
    /* アイコンの位置。微調整してね */
    transform: translateY(-50%);
    right: 5px;
    /* アイコンの位置。微調整してね */
    cursor: pointer;
}

.areaInputSearchText .buttonInputSearchText_Search {
    content: "";
    /* 疑似要素に必須 */
    width: 16px;
    /* アイコンの横幅 */
    height: 16px;
    /* アイコンの高さ */
    background: url(../../public/images/search.png) no-repeat center center / auto 100%;
    /* 背景にアイコン画像を配置 */
    display: inline-block;
    /* 高さを持たせるためにインラインブロック要素にする */
    position: absolute;
    /* 相対位置に指定 */
    top: 50%;
    /* アイコンの位置。微調整してね */
    transform: translateY(-50%);
    left: 5px;
    /* アイコンの位置。微調整してね */
    cursor: pointer;
}

.classCurrentLine {
    background-color: var(--background_highlight);
}

.areaSpeechAnalyzeLimit {
    position: absolute;
    top: 0px;
    right: 0px;
    max-width: 14vw;
}

.areaSpeechAnalyzeLimit>label {
    font-size: 10px;
}

.speechTextEditButtonArea {
    min-height: 28px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

.buttonTextEdit {
    width: auto;
    margin-left: 8px;
    padding: 1px 8px;
    cursor: pointer;
}

.editTagArea {
    position: absolute;
    width: 99%;
    height: calc(100vh - 90px);
    margin-top: 5px;
    border: 1px solid var(--border_color);
    z-index: 10;
    background-color: white;
    border-collapse: collapse;
    overflow-y: auto;
}

.tableEditArea {
    width: 100%;
    border-collapse: collapse;
}

.tableEditAreaTextHeader {
    width: 100%;
    height: auto;
    background-color: var(--button_background);
    color: white;

    position: sticky;
    top: -1px;
}

.tableEditArea>thead>tr>th {
    font-weight: unset;
    text-align: unset;
    border: 1px solid var(--border_color_bold);
    padding: 0px 0px 0px 5px;
}

.tableEditAreaTextBody {
    width: 100%;
    height: 0px;
}

.tableEditArea>tbody>tr>td {
    border: 1px solid var(--border_color_bold);
    padding: 3px 0px;
}

.reliabilityTextArea {
    width: auto;
    margin: 12px 10px;
    padding: 5px 10px;

    position: absolute;
    bottom: 0px;
    left: 0px;
}

.reliabilityTextbox {
    width: 80px;
    padding: 3px;
    border: 1px solid var(--border_color);
}

.speakerTextArea {
    width: auto;
    margin: 12px 10px;
    padding: 5px 10px;

    position: absolute;
    bottom: 0px;
    left: 170px;
}

.speakerTextbox {
    width: 80px;
    padding: 3px;
    border: 1px solid var(--border_color);
}

.buttonApplyEdit {
    width: auto;
    margin: 10px 10px;
    padding: 5px 10px;
    cursor: pointer;

    position: absolute;
    bottom: 0px;
    left: 340px;
}

.buttonCancelEdit {
    width: auto;
    margin: 10px 10px;
    padding: 5px 10px;
    cursor: pointer;

    position: absolute;
    bottom: 0px;
    right: 0px;
}