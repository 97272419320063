.buttonSpeechAnalyze {
    width: auto;
    margin: 0px 10px;
    padding: 0px 10px;
    cursor: pointer;
}

.buttonColorProcessed {
    color: var(--color_speech_analyze);
    background-color: transparent;
    border : 3px solid var(--color_speech_analyze);
    border-radius: 5px;
    cursor: unset;
}

.buttonColorProcessing {
    color: var(--color_speech_analyze);
    background-color: transparent;
    border : 3px solid var(--color_speech_analyze);
    border-radius: 5px;
}

.buttonColorNoProcessed {
    color: var(--text_color_white);
    background-color: var(--color_speech_analyze);
    border : 3px solid var(--color_speech_analyze);
    border-radius: 5px;
}
