.compSettingsAutoRegistTag {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100vh;
    border: 1px solid var(--border_color);
    margin: 0px 0px 0px 0px;
    padding: 10px 0px 0px 15px;
}

.areaSelectUser {
    padding: 8px 0px 24px 0px;
}

.buttonAutoRegistTag {
    margin-left: 10px;
}

.divAutoRegistTagLoadCsv {
    margin-left: 10px;
    display: inline-block;
    position: relative;
}

.inputAutoRegistTagLoadCsv {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.areaAutoRegistTagsHeader {
    margin: 0px 0px 2px 10px;
}

.areaAutoRegistTags {
    width: fit-content;
    margin-left: 10px;
    border: 1px solid var(--border_color);
    height: calc(100vh - 190px);
    overflow-y: scroll;
}

.autoRegistTagTable {
    border-collapse: collapse;
}

.autoRegistTagTable tr th {
    font-weight: unset;
    text-align: unset;
    border: 1px solid var(--border_color);
    padding: 5px;
}

.autoRegistTagTable th {
    color: white;
    background-color: #26779ED9;
    position: sticky;
    top: 0;
    z-index: 1;
}

.autoRegistTagTable tr td {
    border: 1px solid var(--border_color);
    padding: 2px;
    text-align: center;
}

.areaAutoTagErrorMsg {
    height: auto;
}

.AutoTagErrorMsg {
    color: red;
    margin: 0;
}

.areaAutoRegistTagsFooter {
    margin-top: 10px;
}
