.compReferencesSetting {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    border: 1px solid var(--border_color);
    margin: 0px 0px 0px 0px;
}

.gridReferencesSetting {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
}

.areaBody {
    box-sizing: border-box;
    height: calc(100% - 72px);
    overflow-x: hidden;
    overflow-y: hidden;

    display: grid;
    grid-template-rows: auto 1fr;
}

.areaBodyCurrent {
    width: auto;
    height: auto;
}

.areaBodySearched {
    overflow-x: hidden;
    overflow-y: auto;
}

.areaNewIngest {
    height: 30px;
    padding: 5px;
    border: 1px solid var(--border_color);

    background-color: var(--background_white);
    
    position: absolute;
    bottom: 0px;
    right: 0px;
}
