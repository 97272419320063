.compStatisticWhole {
    box-sizing: border-box;
    height: 95%;
    border: 1px solid var(--border_color);
    margin: 0px 0px 0px 0px;
}

.compStatisticWholeTop {
    display: flex;
    width: 100%;
    height: 43%;
}

.compStatisticWholeTopLeft {
    width: 22%;
    height: 100%;
    overflow: hidden;
    padding-right: 4px;
}

.compStatisticWholeTopCenter {
    width: 39%;
    height: 100%;
    overflow: hidden;
    padding: 0px 4px 0px 4px;
}

.compStatisticWholeTopRight {
    width: 39%;
    height: 100%;
    overflow: hidden;
    padding-left: 4px;
}

.areaSearchDuration {
    width: 100%;
    height: 20%;
    align-items: stretch;
}

.areaSearchDurationCombo {
    height: 100%;
}

.areaSearchDurationCombo select {
    display: block;
    font-size: x-large;
    text-align: left;
    height: 40%;
    padding: 0px 20px 0px 0px;
    margin: 0px 0px 0px 30px;
}

.areaAccessCount {
    float: left;
    width: 100%;
    height: 70%;
}

.AccessCount {
    width: 100%;
    height: 100%;
    margin: 0px 0px 0px 0px;
}

.AccessCountLine {
    float: left;
    width: 50%;
    height: 30%;
    background-color: var(--button_background);
    margin: 10px 0px 10px 60px;
    text-align: center;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.AccessCountLine span {
    position: absolute;
    color: white;
    display: block;
    width: 100%;
    font-size: x-large;
    text-align: center;
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.areaRanking {
    width: 100%;
    height: 100%;
}

.compStatisticWholeBottom {
    width: 99%;
    height: 45%;
    border: 1px solid black;
    border-radius: 10px;
    margin: auto auto;
    display: flex;
}

.compStatisticWholeBottomLeft {
    width: 18%;
    height: 100%;
    padding: 0px 4px 0px 0px;
}

.compStatisticWholeBottomCenter {
    width: 40%;
    height: 100%;
    padding: 0px 4px 0px 4px;
}

.compStatisticWholeBottomRight {
    width: 40%;
    height: 100%;
    padding-left: 4px;
}

.areaFacultySelect {
    width: 100%;
    height: 25%;
    align-items: stretch;
    overflow-x: hidden;
    overflow-y: hidden;
}

.areaFacultySelectCombo {
    height: 100%;
}

.areaFacultySelectCombo select {
    display: block;
    font-size: x-large;
    text-align: left;
    overflow: visible;
    height: 35%;
    padding: 0px 20px 0px 0px;
    margin: 0px 0px 0px 30px;
}

.areaFacultyAccessCount {
    width: 100%;
    height: 60%;

}

.areaFacultyMovieRanking {
    width: 100%;
    height: 100%;
}

.labelStatisticsTitle {
    color: var(--button_background);
    margin: 0px 0px 5px 0px;
    font-size: x-large;
    text-decoration: underline;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.areaLabelStatisticsTitle {
    float: left;
    width: 100%;
    text-align: left;
    padding: 20px 5px 4px 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.buttonExportWhole {
    margin-left: 30px;
}
.buttonExportFaculty {
    margin-top: 8px;
    margin-left: 30px;
}