.tablePlayArea {
	width: 100%;
	height: 100%;
	border: 1px solid var(--border_color);
	padding: 2px 0px 0px 5px;
	table-layout: fixed;
	overflow-y: auto;
}

.areaLabelTablePlayArea {
	float: left;
	padding: 10px 0px 5px 0px;
	width: 53%;
}

.labelTablePlayArea {
	color: var(--button_background);
	padding: 0px 0px 10px 0px;
}

.areaTextboxTablePlayArea {
	float: left;
	padding: 10px 0px 5px 0px;
}

.textboxTablePlayArea {
	background-image: url(../../../public/images/search.png);
	background-repeat: no-repeat;
	background-size: 7%;
	background-position: 1px center;
	padding-left: 20px;
}

.labelTablePlayAreaTitle {
	color: var(--button_background);
	text-decoration: underline;
	margin: 0px, 0px, 20px, 0px;
	font-size: x-large;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: bold;
}

.tablePlayArea tr {
	width: 100%;
}

.tablePlayArea>thead>tr>th {
	background-color: var(--button_background);
	color: white;
	text-align: left;
}

.tablePlayArea>tbody>tr>td {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.tableViewedStatus {
	border-collapse: "collapse";
	border: 0;
	border-spacing: 0;
	table-layout: fixed;
}

.tableViewedStatus>tr>td {
	width: 0px;
	height: 8px;
}