.compSettingsOther {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    border: 1px solid var(--border_color);
    margin: 0px 0px 0px 0px;
}

.compSettingsArea {
    width: 100%;
}

.compSettingsOtherBox {
    width: 80%;
    border: 1px solid black;
    margin: 0px 0px 0px 10px;
    padding: 15px 0px 12px 15px;
}

.compSettingsOtherSpeechAnalyzeAreaBody {
    margin: 10px 0px 0px 10px;
}

.compSettingsOtherSpeechAnalyzeAreaBodyL1 {
    width: 100%;
    float: left;
}

.compSettingsOtherSpeechAnalyzeInput {
    width: 80px;
    float: left;
    margin: -5px 15px 5px 10px;
    padding: 3px;
}

.compSettingsOtherCheckbox {
    margin: 0px 0px 0px 10px;
}

.compSettingsOtherBodyText {
    float: left;
    color: black;
}

.compSettingsOtherBodyTextPaddingL {
    color: black;
    margin: 0px 0px 20px 10px;
}

.compSettingsOtherLabelTitle {
    color: var(--text_color_light);
    margin: 0px 0px 0px 0px;
    font-size: x-large;
    text-decoration: underline;
}

.compSettingsOtherAreaLabelTitle {
    width: 100%;
    text-align: left;
    padding: 20px 0px 5px 20px;
}

.buttonRegistOtherSettings {
    margin: 25px 0px 0px 10px;
}

.compSettingsOtherErrorMsg {
    color: red;
    font-size: 90%;
}

.compSettingsCinnamonArea {
    margin: 10px 0px 0px 0px;
}

.compSettingsCinnamonTable {
    margin: 0px 0px 0px 20px;
}

.compSettingsCinnamonTitleValue {
    padding: 5px 12px 0px 0px;
}
