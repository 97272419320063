.compStatisticUser {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    height: 95%;
    border: 1px solid var(--border_color);
    margin: 0px 0px 0px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.areaDummyCombo {
    position: absolute;
    top: 10px;
    right: 5px;
}

.compStatisticUserLeftArea {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    width: 39%;
    height: 100%;
}

.compStatisticUserLeftArea_UserSelect {
    width: 100%;
    height: 20%;
    align-items: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.areaUserSelectCombo {
    height: 100%;
}

.areaUserSelectCombo select {
    display: block;
    font-size: x-large;
    text-align: left;
    height: 20%;
    padding: 0px 20px 0px 0px;
    margin: 0px 0px 0px 30px;
}

.tableBasicInfo {
    margin: 10px 0px 10px 30px;
    border-collapse: collapse;
}

.tableBasicInfo tr {
    border: 1px solid var(--border_color);
    width: 100%;
}

.tableBasicInfo>thead>tr>th {
    border: 1px solid var(--border_color);
    background-color: var(--button_background);
    color: white;
    text-align: left;
}

.tableBasicInfo>tbody>tr>td {
    border-spacing: 0;
    border: 1px solid var(--border_color);
    text-align: left;
}

.tableCommentHistory {
    margin: 20px 0px 0px 30px;
    border: 1px solid var(--border-color);
    border-collapse: collapse;
}

.tableCommentHistory tr {
    border: 1px solid var(--border_color);
    width: 100%;
}

.tableCommentHistory>thead>tr>th {
    border: 1px solid var(--border_color);
    background-color: var(--button_background);
    color: white;
    text-align: left;
}

.tableCommentHistory>tbody>tr>td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 0;
    border-spacing: 0;
    border: 1px solid var(--border_color);
    text-align: left;
}

.areaTeacherViewCount {
    width: 100%;
    height: 50%;
}

.teacherViewCount {
    width: 100%;
    height: 100%;
    margin: 0px 0px 0px 0px;
}

.teacherViewCountLine {
    width: 30%;
    height: 50px;
    background-color: var(--button_background);
    margin: 10px 10px 0px 20px;
    text-align: center;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.teacherViewCountLine span {
    position: absolute;
    color: white;
    display: block;
    width: 100%;
    font-size: xx-large;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.compStatisticUserRightArea {
    float: left;
    width: 58%;
    height: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.compStatisticUserRightAreaStudent {
    float: left;
    width: 100%;
    height: 100%;
}

.compStatisticUserRightAreaStudent_Box {
    float: left;
    width: 99%;
    height: 85%;
    border: 1px solid black;
    border-radius: 10px;
    margin: 5px 0px 0px 0px;
}

.compStatisticUserRightAreaStudent_Top {
    width: 100%;
    height: 50%;
}

.compStatisticUserRightAreaStudent_Top_SearchDuration {
    float: left;
    width: 38%;
    overflow: hidden;
    padding-right: 5px;
}

.compStatisticUserRightAreaStudent_Top_PlayAreaTable {
    overflow: hidden;
    float: left;
    width: 60%;
    height: 100%;
    overflow-y: auto;
    margin-top: 10px;
}

.compStatisticUserRightAreaStudent_Bottom {
    width: 100%;
    height: 50%;
}

.compStatisticUserRightAreaStudent_Bottom_PlayStatus {
    float: left;
    width: 50%;
    height: 90%;
}

.compStatisticUserRightAreaStudent_Top_TagRanking {
    float: left;
    width: 50%;
    height: 90%;
}

.compStatisticUserRightAreaTeacher {
    float: left;
    width: 70%;
    height: 60%;
}

.compStatisticUserRightAreaNotify{
    font-size: larger;
    color: var(--border_color_invalid);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.areaLabelStatisticsUserTitle {
    float: left;
    display: flex;
    justify-content: space-between;
    width: 80%;
    text-align: left;
    padding: 20px 20px 4px 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.linkText {
    color: blue;
    text-decoration: underline;
    transition: border-bottom .3s ease;

    &:hover {
        text-decoration: none;
        border-bottom: 0px solid blue;
    }
}

.buttonExportUserViewedHistory {
    margin-top: 15px;
    margin-left: 30px;
}