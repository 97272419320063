.fromTop {
    position: absolute;
    top: 0px;
}

.allHome {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: grid;
    grid-template-columns: 200px 1fr 2fr;
}

.backgroundGrayGradiation {
    background: linear-gradient(lightgray, 75%, gray);
    color: black;
}
.backgroundGrayGradiation:disabled {
    opacity: 0.5;
    background: linear-gradient(lightgray, 75%, gray);
    color: black;
}

.backgroundGreenGradiation {
    background: linear-gradient(lightgreen, 75%, green);
    color: black;
}

.areaLabelTitle {
    padding: 0px 0px 5px 0px;
}

.labelTitle {
    font-weight: bold;
}

.widthFull {
    width: -webkit-fill-available;
    width: -moz-available;
}

.buttonPadding {
    width: auto;
    margin: 10px 10px;
    padding: 5px 10px;
    cursor: pointer;
}

.imageDummySize {
    object-position: left top;
    object-fit: contain;
    width: 95%;
    height: 95%;
}