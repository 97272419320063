.Ranking {
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.Ranking span {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.labelRankingTitle {
	color: var(--button_background);
	margin: 0px 0px 5px 0px;
	font-size: x-large;
	text-decoration: underline;
	font-weight: bold;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.areaLabelRankingTitle {
	width: 100%;
	text-align: left;
	padding: 20px 5px 23px 20px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.RankingLineTopThree {
	float: left;
	width: 80%;
	height: 12%;
	margin: 8px 0px 0px 0px;
}

.RankingLineTopCrown {
	z-index: 2;
	position: absolute;
	transform: rotate(-30deg);
	top: -20px;
	left: -40px;
}

.RankingLineTopCrown img {
	z-index: 2;
	width: 60%;
}

.RankingLineBoxTopThree {
	color: white;
	float: left;
	width: 65%;
	height: 100%;
	background-color: var(--button_background);
	margin: 0px 0px 0px 20px;
	text-align: center;
	position: relative;
}

.RankingLineBoxTopThree span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	font-size: large;
	text-align: left;
	font-weight: bold;
}

.RankingRightArrowTopThree {
	float: left;
	background-color: var(--button_background);
	width: 4%;
	height: 100%;
	clip-path: polygon(0 0, 0 100%, 90% 50%);
}

.RankingLineCountBoxTopThree {
	color: white;
	float: left;
	width: 20%;
	height: 100%;
	border-radius: 10px;
	background-color: var(--button_background);
	text-align: center;
	position: relative;
}

.RankingLineCountBoxTopThree span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	font-size: large;
	text-align: center;
	font-weight: bold;
}

.RankingLine {
	float: left;
	width: 100%;
	height: 17px;
	margin: 3px 0px 0px 20px;
}

.RankingLineBox {
	width: 50%;
	float: left;
	font-size: medium;
	text-align: left;
}

.RankingLineBox span {
	font-size: medium;
	display: block;
}

.RankingLineCountBox {
	width: 10%;
	float: left;
	color: black;
	margin: 0px 10px 0px 0px
}