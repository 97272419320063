:root {
  --button_background: rgba(38, 119, 158, 1.0);
  --button_background_hover: rgba(21, 67, 90, 1.0);
  --select_background: rgba(38, 119, 158, 0.18);
  --select_background_hover: rgba(61, 178, 236, 0.18);
  --select_border: rgba(38, 119, 158, 1.0);
  --border_color: lightgray;
  --border_color_bold: gray;
  --border_color_invalid: red;
  --background_white: white;
  --background_light: lightgray;
  --background_lightlight: #EEEEEE;
  --background_highlight: rgba(248, 183, 107, 1.0);
  --text_color_white: white;
  --text_color_light: gray;
  --color_deliver: rgba(248, 183, 107, 1.0);
  --color_speech_analyze: rgba(38, 119, 158, 1.0);
  --letter_spacing: 0.05em;
}

body {
  background-color: white;
  overflow-y: hidden;
  font-size: 16px;
  font-family: "BIZ UDPゴシック";
  letter-spacing: var(--letter_spacing);
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

input {
  font-size: 16px;
  font-family: "BIZ UDPゴシック";
  letter-spacing: var(--letter_spacing);
}

.sizeIconSmall {
  width: 16px;
  height: 16px;
}