.homeCenter {
    box-sizing: border-box;
    padding: 0px 10px 0px 10px;

    overflow: hidden;
    resize: horizontal;
    min-width: 100%;
}

.gridParentManagement,
.gridParentMovieWatch {
    height: 100vh;
    overflow: hidden;
}

.gridParentManagement {
    display: grid;
    grid-template-rows: 31vh auto 40vh auto;
}

.gridParentMovieWatch {
    display: grid;
    grid-template-rows: 31vh auto 40vh auto;
}