.compAssetMeta {
    padding: 0px 0px 0px 0px;
    overflow-y: auto;
    height: 100%;
}

.areaMetaLabelTitle {
    padding: 0px;
}

.labelTitle {
    padding: 0px 0px 0px 0px;
}

.compAssetMeta>table,
td,
th {
    border-collapse: collapse;
}

.compAssetMeta>table>thead>tr>th {
    font-weight: unset;
    text-align: unset;
    border: 1px solid var(--border_color);
    padding: 0px 0px 0px 5px;
}

.compAssetMeta>table>tbody>tr>td {
    border: 1px solid var(--border_color);
}

.tableMetaBody {
    width: 100%;
    height: 0px;
    background-color: #DDDDFF;
}

.labelKey {
    background-color: var(--button_background);
    color: white;
    width: 33%;
    font-size: 12px;
    padding: 5px 0px 5px 0px;
}

.labelValue {
    background-color: var(--background_lightlight);
    color: black;
    font-size: 12px;
}

.inputMetaValue {
    border: 1px solid var(--border_color);
    font-size: xx-small;
}

.inputMetaValueInvalid {
    border: 1px solid var(--border_color_invalid);
    font-size: xx-small;
}

.buttonMetaSave {
    float: right;
    margin: 5px 0px 5px 5px;
}

.buttonDeleteReference {
    border: 1;
    width: 30px;
    height: 20px;
    margin: 1px 0px 1px 0px;
}

.buttonUploadReference {
    margin: 0px;
}