.custom-creatable-select {
  width: 500px;
  height: 45px; /* Adjust the height according to your requirement */
  margin-top: -25px;
  margin-left: 10px;
}

.custom-creatable-select .react-select__control {
  min-height: 45px; /* Not working? */
}
