.compTagInfo {
    height: 100vh;
    overflow: hidden;

    border: 1px solid var(--border_color);
}

.gridTagInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.areaAllHeight {
    padding: 5px;

    width: 100%;
    height: 100%;
    border: 1px solid var(--border_color);
}

.areaAllTag {
    padding: 5px 5px 15px 5px;
    height: calc(100% - 60px);
    overflow-x: hidden;
    overflow-y: hidden;
}

.areaTableAllTag {
    float: left;
    height: 100%;
    overflow-y: auto;
}

.tableAllTag {
    border-collapse: collapse;
}

.tableAllTag>thead>tr>th {
    font-weight: unset;
    text-align: unset;
    border: 1px solid var(--border_color_bold);
    padding: 0px 0px 0px 5px;
}

.tableAllTag>tbody>tr>td {
    border: 1px solid var(--border_color_bold);
}

.tableAllTagHeader {
    width: 100%;
    height: 0px;
    background-color: var(--button_background);
    color: white;
}

.tableAllTagBody {
    width: 100%;
    height: 0px;
    background-color: var(--background_white);
}

.areaTagSetting {
    padding: 5px 5px 5px 5px;

    display: grid;
    grid-template-rows: auto auto 1fr;
}

.buttonInsertNewTag {
    float: right;
    margin: 5px 0px 5px 5px;
}

.areaInsertTagErrorMsg {
    height: auto;
}

.TagErrorMsg {
    color: red;
    margin: 0;
}