.compWatchInfo {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    border: 1px solid var(--border_color);
    margin: 0px 0px 0px 0px;
}

.areaChartTop {
    width: 100%;
    height: 40%;
    align-items: center;
    justify-content: center;
    padding: 1;
}

.areaChartBottom {
    width: 100%;
    height: 40%;
    margin: 100px 0px 0px 0px;
}

.areaChartBottomLeft {
    height: 100%;
    width: 45%;
    float: left;
    align-items: center;
    justify-content: center;
    padding: 1;
    margin: 0px 0px 0px 10px;
}

.areaChartBottomRight {
    height: 100%;
    width: 50%;
    float: left;
    align-items: center;
    justify-content: center;
    padding: 1;
    margin-left: 3px;
}

.topChartSwiper {
    width: 70%;
    height: 50vh;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    overflow-y: auto;
}

.topChartSwiperSlides {
    overflow-y: auto;
}

.chartPlayCount {
    width: 99%;
    height: 99%;
}

.chartPlayHistory {
    width: 99%;
    height: 99%;
}

.chartPlayStatus {
    width: 100%;
    height: 100%;
    margin-left: 10px;
    margin-right: auto;
}

.areaLabelRankingTitleWatchInfo {
    width: 100%;
    text-align: left;
    padding: 0px 5px 23px 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}