.compFileList {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    border: 1px solid var(--border_color);
    margin: 0px 0px 0px 0px;
}

.gridFileList {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
}

.bodyFileListItem {
    box-sizing: border-box;
    height: calc(100% - 72px);
    overflow-x: hidden;
    overflow-y: auto;
}

.areaNewIngest {
    height: 30px;
    padding: 5px;
    border: 1px solid var(--border_color);

    background-color: var(--background_white);
    
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.buttonNewIngestFromFileList {
    width: auto;
    /* margin: 10px 10px;
    padding: 5px 10px; */
    cursor: pointer;
}

.buttonNewIngestFromFileList > label > img {
    vertical-align: text-top;
    margin-right: 5px;
}