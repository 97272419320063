.compStatisticMaintenance {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 95%;
    border: 1px solid var(--border_color);
    margin: 0px 0px 0px 0px;
}

.compStatisticMaintenanceTop {
    float: left;
    width: 100%;
    height: 50%;
}

.areaCompStatisticMaintenanceSpeechAnalyzeTimeChart {
    float: left;
    width: 50%;
    height: 80%;
    margin: 10px 0px 0px 0px;
}

.areaCompStatisticMaintenanceMoviePlayCountWorst {
    float: left;
    width: 40%;
    height: 98%;
    margin: 10px 0px 0px 30px;
}

.compStatisticMaintenanceBottom {
    float: left;
    width: 100%;
    height: 40%;
}

.compStatisticMaintenanceSearchDate {
    white-space: nowrap;
    margin: 0px 0px 10px 15px;
}

.speechAnalyzeRatio_Teacher {
    float: left;
    width: 40%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.speechAnalyzeRatio_Room {
    float: left;
    width: 40%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.areaTableSpeechAnalyzeRatio {
    float: left;
    width: 100%;
    height: 100%;
}

.tableSpeechAnalyzeRatio {
    width: 100%;
    margin: 5px 0px 10px 30px;
    border-collapse: collapse;
    table-layout: fixed;
}

.tableSpeechAnalyzeRatio>tr {
    border: 1px solid var(--border_color);
    width: 100%;
}

.tableSpeechAnalyzeRatio>thead>tr>th {
    border: 1px solid var(--border_color);
    background-color: var(--button_background);
    color: white;
    text-align: left;
}

.tableSpeechAnalyzeRatio>tbody>tr>td {
    border-spacing: 0;
    border: 1px solid var(--border_color);
    text-align: left;
}

.areaSpeechAnalyzeRatioLabelTitle {
    float: left;
    width: 40%;
    color: var(--button_background);
    margin: 0px 0px 0px 10px;
    font-size: x-large;
    text-decoration: underline;
}

.SpeechAnalyzeRatioLabelTitle {
    width: 100%;
    text-align: left;
    padding: 20px 0px 5px 20px;
}