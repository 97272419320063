.compAssetTag {
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    height: auto;
}

.buttonTag {
    width: auto;
    height: auto;
    box-sizing: border-box;
    padding: 5px 5px 5px 5px;
    margin: 0px 5px 0px 5px;
    color: var(--text_color_light);
    background-color: transparent;
    border: 1px solid var(--border_color_bold);
    border-radius: 5px;
}

.buttonTagDispAll {
    border: none;
    background-color: transparent;
}

.buttonTagDispAll:focus,
.buttonTagDispAll:hover {
    outline: none;
    background: lightgray;
}

.drag-list li {
    list-style: none;
    display: inline-block;
    cursor: pointer;
    font-size: smaller;
}