.compFileListItem {
    margin: -1px -1px -1px -1px;
}

.areaThumbnail {
    width: 33%;
    min-width: 240px;
    text-align: center;
}

.imgThumbnail {
    width: 100%;
    text-align: center;
}

.selectedItem {
    padding: 2px;
    border: 5px solid var(--select_border);
    background-color: var(--select_background);
}

.nonSelectItem {
    padding: 5px;
    border: 1px solid var(--border_color);
    background-color: transparent;
}

.buttonAssetDelete {
    visibility: initial;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.buttonAssetFavorite {
    visibility: initial;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.buttonDeliverd {
    width: auto;
    margin: 0px 10px;
    padding: 0px 10px;
    cursor: pointer;

    color: var(--text_color_white);
    background-color: var(--color_deliver);
    border: 3px solid var(--color_deliver);
    border-radius: 5px;
}

.buttonNoDeliverd {
    width: auto;
    margin: 0px 10px;
    padding: 0px 10px;
    cursor: pointer;

    color: var(--color_deliver);
    background-color: transparent;
    border: 3px solid var(--color_deliver);
    border-radius: 5px;
}

.buttonDeliverd>label>img {
    vertical-align: text-top;
    margin-right: 5px;

    filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(5%) hue-rotate(137deg) brightness(104%) contrast(100%);
}

.buttonNoDeliverd>label>img {
    vertical-align: text-top;
    margin-right: 5px;

    filter: brightness(0) saturate(100%) invert(99%) sepia(66%) saturate(6822%) hue-rotate(306deg) brightness(96%) contrast(103%);
    /* rgb(248, 183, 107) : #f8b76b*/
}

.buttonReferenceCurrent {
    color: var(--color_speech_analyze);
    background-color: transparent;
    border: 3px solid var(--color_speech_analyze);
    border-radius: 5px;
    cursor: unset;
    margin: 0px 10px;
}

.buttonReferenceReferenced {
    color: var(--color_speech_analyze);
    background-color: transparent;
    border: 3px solid var(--color_speech_analyze);
    border-radius: 5px;
    margin: 0px 10px;
}

.buttonReferenceNoReference {
    color: var(--text_color_white);
    background-color: var(--color_speech_analyze);
    border: 3px solid var(--color_speech_analyze);
    border-radius: 5px;
    margin: 0px 10px;
}

.buttonRecovery {
    border: none;
    width: 100px;
    background-color: var(--button_background);
}

.labelButtonRecovery {
    color: white;
}

.cursorUnset {
    cursor: unset;
}

.positionTR {
    position: absolute;
    top: 0;
    right: 0;
}

.visibleNone {
    visibility: collapse;
}