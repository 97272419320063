html,
body {
  height: 100%;
  overflow: hidden;
}
#root {
  height: 100%;
  overflow: auto;
}

.date-picker-style {
  width: 200px;
  height: 35px;
  margin-top: -3.5px;
  margin-bottom: 7.5px;
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-progress {
  width: 100%;
  height: 20px;
  background-color: white;
  margin-bottom: 20px;
}

.upload-progress-bar {
  width: 100%;
  height: 100%;
}

.cancel-button {
  background-color: red;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.button-round {
  border-radius: 25% 25% 25% 25% / 100% 100% 100% 100%;
}
.button-round-short {
  border-radius: 40% 40% 40% 40% / 100% 100% 100% 100%;
}

.form-group {
  white-space: nowrap;
}

.dragDropMessage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 255, 0.5); /* Blue with 50% opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 1.2rem;
  pointer-events: none;
}

.bottom-large-mergin {
  margin-bottom: 20px;
}

.bottom-small-mergin {
  margin-bottom: 5px;
}

.label-fixed-width {
  display: inline-block;
  width: 100px;
  text-align: right;
  margin-right: 10px;
  margin-left: -100px;
}
.custom-input {
  width: 200%;
  margin-left: 0px;
}

.videoSelectButton {
    display: block;
    margin: auto;
    height: 32px;
}

.videoPlayerContainer {
  position: relative;
  width: 640px;
  height: 360px;
  overflow: hidden;
}

.videoPlayerContainer .video-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
  
.compVideoViewer {
    padding: 5px 0px 5px 0px;
}

.returnButton {
  width: auto;
  height: 33px;
  margin: 10px 10px;
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
  top: 10px;
  left: "0%";
  float: "left"
}

.buttonNewIngest {
    width: auto;
    margin: 20px 30px;
    padding: 5px 10px;
    cursor: pointer;
    position: relative;
    top: 0px;
    left: "0%";
    float: "left"
}

.buttonNewIngest > label > img {
    width: 16px;
    vertical-align: middle;
    margin-right: 5px;
}

.uploadVideoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.videoPlayer {
    width: 640px;
    height: 360px;
}

.custom-select {
  width: 500px;
  height: 45px; /* Adjust the height according to your requirement */
  margin-top: -25px;
  margin-left: 10px;
}

.areaViewLimitation {
	padding: 5px 5px 15px 5px;
	height: calc(100% - 60px);
	overflow-y: hidden;
	align-items: center;
	justify-content: center;
}

.buttonTableTop {
	float: right;
	margin: 5px 0px 5px 5px;
}

.areaTableStudents {
  position:relative;
  width: 100%;
  height: calc(100vh / 4);
  overflow-y: auto;
}

.tableStudents {
	border-collapse: collapse;
}

.tableStudents>tr>th {
	font-weight: unset;
	text-align: unset;
	border: 1px solid var(--border_color_bold);
	padding: 0px 0px 0px 5px;
}

.tableStudents>tr>td {
	border: 1px solid var(--border_color_bold);
}

.tableStudentsHeader {
  height: auto;
  background-color: var(--button_background);
  color: white;

  position: sticky;
  top: -1px;
}

.tableStudentsBody {
	width: 100%;
	height: 0px;
	background-color: var(--background_white);
}

.areaButtonTableTop {
	width: 100%;
	float: left;
}
